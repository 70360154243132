@import 'libs/styles/variables';

$red: $background-color-toggle;
$gray: $primary-black-table-header;
$drag-drop-bg-color: $background-color-sidebar;
$outline-color: $outline-color;
.out-line.active {
    outline-color: $outline-color;
    outline-style: groove;
    outline-width: 5px;
}

.out-line.deactive {
    outline: none;
}

.drag-drop {
    background-color: white;
    height: 11rem;
    width: 100%;
    border-style: dashed;
    border-width: 1px;
    padding: 3rem 10rem;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.drag-drop.data-null {
    border-color: $gray;
    color: $gray;
}

.drag-drop.data-not-null {
    border-color: $red;
    color: $red;
}
