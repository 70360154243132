.p-overflow-hidden {
  -ms-overflow-style: none; // To fix issue of overlapping scroll bar on IE 10+
}

.vida-sidebar {
  &.p-sidebar-right {
    width: 500px;
  }

  padding: 0px;
  -ms-overflow-style: -ms-autohiding-scrollbar; // To fix issue of overlapping scroll bar on IE 10+

  &.hide-default-close > .p-sidebar-close {
    display: none !important;
  }

  .p-sidebar-close {
    width: 30px;
    height: 30px;
    top: 12px;

    .pi {
      font-size: 2em;
      color: #000
    }
  }

  .p-sidebar-content,
  .sidebar-content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .sidebar-header {
      flex: 0 1 auto;
      border-bottom: 1px solid $color-4;
      padding: 18px 20px 18px;

      h1 {
        color: $primary-dark-grey;
        font-size: 1.3em;
        font-weight: 500;
        line-height: 1.4;
        margin: 0;
      }
    }

    .sidebar-content {
      flex: 1 1 auto;
      overflow: auto;
      padding: 20px;
    }

    .sidebar-footer {
      flex: 0 1 auto;
      display: flex;
      margin: 10px;

      .btn {
        flex-grow: 1;
        margin: 0 10px;
      }
    }
  }

  // .sidebar-header {
  //   border-bottom: 1px solid $primary-grey;
  //   padding: 18px 20px 18px;

  //   h1 {
  //     color: $primary-grey;
  //     font-size: 1.3em;
  //     font-weight: 500;
  //     line-height: 1.4;
  //     margin: 0;
  //   }
  // }

  // .sidebar-content {
  //   position: absolute;
  //   top: 62px;
  //   bottom: 90px;
  //   left: 0;
  //   right: 0;
  //   padding: 20px 20px 0;
  //   overflow-y: auto;
  // }

  .sidebar-footer {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   display: flex;
  //   padding: 25px 40px;
  //   background-color: #fff;

  //   .btn {
  //     flex-grow: 1;
  //   }

    .lnk-cancel {
      text-decoration: underline;
      font-weight: bold;
      border: none;
      &:hover {
        background-color: initial;
        color: initial;
      }
    }
  }
}

call-sheet-sidebar,
post-job-report-sidebar {
  height: 100%;

  .sidebar-block {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

/* Hide the page scrollbar when the sidebar is open */
.hide-scrollbar {
  overflow: hidden; /* Prevent page scrolling */
}
