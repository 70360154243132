/**
 * Load core stylesheet of the app
 */

// Variables
@import "./variables";

// Flexbox
@import "libs/styles/core/index";
@import "libs/styles/flexbox/index";

// Application stylesheet

@import "libs/styles/bootstrap";
@import "libs/styles/base";
@import "libs/styles/mixins";
@import "libs/styles/utils";
@import "libs/styles/loading";
@import "libs/styles/nav";
@import "libs/styles/button";
@import "libs/styles/dialog";
@import "libs/styles/form";
@import "libs/styles/input";
@import "libs/styles/section";
@import "libs/styles/sidebar";
@import "libs/styles/table";
@import "libs/styles/tab";
@import "libs/styles/modals";
@import "libs/styles/validation";

// Customization PrimeNG
@import "./bootstrap-customization/index";
@import "./bootstrap4-flexgrid/index";
@import "./primeng-customization/index";
@import "./vida/index";
@import "./dragdrop";
