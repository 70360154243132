@import 'libs/styles/variables';

.form-control.disabled {
  cursor: not-allowed;
  background: #eeeeee;
}

.form-header-section {
  height: 25px;
  color: #484848;
  font-size: 16px;
  border-bottom: 1px solid #222222;
  font-weight: bold;
  margin-bottom: 15px;
}

.form-title-section {
  height: 30px;
  line-height: 30px;
  padding: 0px 20px;
  color: $secondary-white;
  background: $primary-black;
  font-weight: bold;
  // margin-left: auto;
  // margin-right: auto;
  width: auto;
  display: inline-block;
}

.form-subtitle-section {
  height: 30px;
  line-height: 30px;
  padding: 0px 20px;
  color: $secondary-white;
  background: $color-2;
  // font-weight: bold;
  // margin-left: auto;
  // margin-right: auto;
  width: auto;
  display: inline-block;
}
