/**
 * PrimeNG 4.3.0
 * Customization of PrimeNG Miscellaneous components
 */

/**
 * PrimeNG Progress bar
 */
.p-progressbar {
  &.p-progressbar-determinate .p-progressbar-value {
    border-radius: 0;
    background: $primary-grey;
  }

  &.success .p-progressbar-value {
    background-color: $secondary-green;
  }

  &.warning {
    background-color: $secondary-yellow;
  }

  &.error .p-progressbar-value {
    background-color: $secondary-red;
  }
}

// .ui-blockui {
//   position: fixed;
// }

// .ui-paginator {
//   background-color: #f4f4f4;
//   border: 1px solid #c8c8c8;
//   padding: 0;

//   .ui-paginator-first,
//   .ui-paginator-prev,
//   .ui-paginator-next,
//   .ui-paginator-last {
//     color: #848484;
//     height: 2.286em;
//     min-width: 2.286em;
//     border: 0 none;
//     line-height: 2.286em;
//     padding: 0;
//     margin: 0;
//     vertical-align: top;
//     -moz-border-radius: 0;
//     -webkit-border-radius: 0;
//     border-radius: 0;
//   }

//   .ui-paginator-first:not(.p-disabled):not(.ui-state-active):hover,
//   .ui-paginator-prev:not(.p-disabled):not(.ui-state-active):hover,
//   .ui-paginator-next:not(.p-disabled):not(.ui-state-active):hover,
//   .ui-paginator-last:not(.p-disabled):not(.ui-state-active):hover {
//     background-color: #e0e0e0;
//     color: #333333;
//   }

//   .ui-paginator-current {
//     color: #848484;
//     height: 2.286em;
//     min-width: 2.286em;
//     line-height: 2.286em;
//   }
//   .ui-dropdown {
//     border: 0 none;
//   }
//   .ui-dropdown .ui-dropdown-trigger,
//   .ui-dropdown .ui-dropdown-label {
//     color: #848484;
//   }
//   .ui-dropdown:hover .ui-dropdown-trigger,
//   .ui-dropdown:hover .ui-dropdown-label {
//     color: #333333;
//   }
//   .ui-paginator-first:before {
//     position: relative;
//     top: 1px;
//   }
//   .ui-paginator-prev:before {
//     position: relative;
//   }
//   .ui-paginator-next:before {
//     position: relative;
//     top: 1px;
//   }
//   .ui-paginator-last:before {
//     position: relative;
//     top: 1px;
//   }
//   .ui-paginator-pages {
//     vertical-align: top;
//     display: inline-block;
//     padding: 0;
//   }
//   .ui-paginator-pages .ui-paginator-page {
//     color: #848484;
//     height: 2.286em;
//     min-width: 2.286em;
//     border: 0 none;
//     line-height: 2.286em;
//     padding: 0;
//     margin: 0;
//     vertical-align: top;
//     -moz-border-radius: 0;
//     -webkit-border-radius: 0;
//     border-radius: 0;
//   }
//   .ui-paginator-pages .ui-paginator-page.ui-state-active {
//     background-color: #007ad9;
//     color: #ffffff;
//   }
//   .ui-paginator-pages .ui-paginator-page:not(.ui-state-active):hover {
//     background-color: #e0e0e0;
//     color: #333333;
//   }
//   .ui-dropdown {
//     margin-left: 0.5em;
//     height: 2.286em;
//     min-width: auto;
//   }
// }

.ui-button {
  margin: 0;
  color: #444;
  background-color: $secondary-grey;
  border: 1px solid $secondary-grey;
  font-size: 14px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;

  /*:enabled:hover {
    background-color: $secondary-dark-grey;
    /*color: #ffffff;*/
  /*border-color: #116fbf;*/
  /*}

  /*:enabled:focus {
    outline: 0 none;
    outline-offset: 0px;
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff;
  }

  :enabled:active {
    background-color: #005b9f;
    color: #ffffff;
    border-color: #005b9f;
  }

  &.ui-button-text-only .ui-button-text {
    padding: 0.429em 1em;
  }
  &.ui-button-text-icon-left .ui-button-text {
    padding: 0.429em 1em 0.429em 2em;
  }
  &.ui-button-text-icon-right .ui-button-text {
    padding: 0.429em 2em 0.429em 1em;
  }
  &.ui-button-icon-only {
    width: 2.357em;
  }
  &.ui-button-icon-only .ui-button-text {
    padding: 0.429em;
  }
  &.ui-button-raised {
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  }
  &.ui-button-rounded {
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
  }*/
}

.p-disabled,
.p-component:disabled {
  color: $gray;
  background-image: none;
  cursor: default !important;
}

p-calendar {
  word-break: initial !important; // fix datepicker height inside tables
}

*:focus {
  outline-width: 0;
}

.dropdown-menu a {
  text-decoration: none !important;
}

.w-100 {
  width: 100%
}