.p-toast {
  .p-toast-message {
    max-width: 400px;
  
    .p-toast-message-content {
      display: flex;
    
      .toast-block {
        order: 1;
        display: flex;
        width: 100%;
  
        .icon-wrapper {
          width: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
  
          .pi {
            font-size: 2rem;
            font-weight: 600;
          }
        }
        
        .text-wrapper {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          padding-left: 5px;

          h4 {
            font-size: 1em;
            margin: 0.5em 0em;
          }
  
          p {
            font-size: 0.9em;
            margin: 0.5em 0em;
          }
        }      
      }
    
      .p-toast-icon-close {
        order: 2;
        position: relative;
        top: -6px;
        left: 6px;
  
        .p-toast-icon-close-icon {
          font-size: 0.8em;
          font-weight: 600;
        }
      }
    }
  }   
}