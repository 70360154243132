@import 'libs/styles/variables';

.fa.fa-play {
  color: $secondary-white;
  font-size: 16px;
  top: 3px;
  transform: rotate(-360deg);

  &.expanded {
    transform: rotate(90deg);
  }
}
