@import "mixins/_breakpoints.scss";
@import "mixins/_grid-framework.scss";
@import "mixins/_grid.scss";

.flx-row {
  @include make-flex-row();
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  >.flx-col,
  >[class*="flx-col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@include make-flex-grid-columns();
